// ---------------------------------------------
// ======---------------------------------======
// Random one off bits and bobs
// ======---------------------------------======
// ---------------------------------------------

const body = document.querySelector("body")
// ====---------------====
// Sharing options
// ====---------------====
const sharingOptions = document.querySelector(".share-options")
const postPageContent = document.querySelector(".post-page__content")

function setShareOffset() {
	const postPageOffset = postPageContent.getBoundingClientRect().top
	if (postPageOffset > 100) {
		body.style.setProperty("--share-y-pos", `${postPageOffset}px`)
		body.setAttribute("data-sh-sticky", "no-header")
	} else {
		body.style.setProperty("--share-y-pos", "0px")
		body.setAttribute("data-sh-sticky", "header")
	}
}

if (sharingOptions && postPageContent) {
	setShareOffset()
	window.addEventListener("scroll", setShareOffset, false)
}

if (sharingOptions) {
	const sharingOptionsArry = document.querySelectorAll(".share-options")
	const sharePageUrl = window.location.href
	let soLinkedin = `https://www.linkedin.com/shareArticle?mini=true&url=${sharePageUrl}`
	let soPage = `${sharePageUrl}`

	function setLinks(pageLink, val) {
		pageLink.setAttribute("href", val)
	}

	function copiedSuccess(parentEl, linkEl) {
		const msg = "link copied"
		const linkOffset =
			linkEl.getBoundingClientRect().top - parentEl.getBoundingClientRect().top

		let msgEl = document.createElement("div")
		msgEl.innerHTML = msg
		msgEl.classList.add("share-options__msg", "share-options__msg--success")
		msgEl.style.setProperty("--msg-offset", `${linkOffset}px`)
		parentEl.appendChild(msgEl)

		setTimeout(() => {
			msgEl.remove()
		}, 800)
	}

	function updateClipboard(newClip, cb) {
		navigator.clipboard.writeText(newClip).then(
			() => {
				/* clipboard successfully set */
				console.log("success")
				cb
			},
			() => {
				/* clipboard write failed */
				console.log("failed")
			}
		)
	}

	sharingOptionsArry.forEach(sharingOption => {
		const soPageLinks = sharingOption.querySelectorAll(
			'a[data-link="page-url"]'
		)
		soPageLinks.forEach(pageLink => {
			setLinks(pageLink, soPage)
			pageLink.addEventListener("click", e => {
				e.preventDefault()

				navigator.permissions
					.query({ name: "clipboard-write" })
					.then(result => {
						if (result.state === "granted" || result.state === "prompt") {
							updateClipboard(soPage, copiedSuccess(sharingOption, pageLink))
						}
					})
			})
		})

		const soLinkedinLinks = sharingOption.querySelectorAll(
			'[data-link="linkedin"]'
		)
		soLinkedinLinks.forEach(pageLink => setLinks(pageLink, soLinkedin))
	})
}

// ====---------------====
// Chonky tabs
// ====---------------====
const chonkyTabs = document.querySelectorAll(".chonky-tabs")
if (chonkyTabs.length > 0) {
	function clearChonkyTabs(tab) {
		const activeTabs = tab.querySelectorAll(".chonky-tabs__content .js-active")
		activeTabs.forEach(x => x.classList.remove("js-active"))
	}

	function chonkyTabsTransition(timing, clickedTab, parentTab) {
		// if mobile handle as accordion
		if (clickedTab.classList.contains("js-active") && window.outerWidth < 991) {
			clickedTab.classList.remove("js-active")
			clickedTab.nextElementSibling.classList.add("js-out")

			setTimeout(() => {
				clickedTab.nextElementSibling.classList.remove("js-active")
				clickedTab.nextElementSibling.classList.remove("js-out")
			}, timing)
		} else {
			const oldTab = parentTab.querySelectorAll(".js-active")
			// add animate out class
			if (oldTab.length > 0) oldTab[1].classList.add("js-out")
			if (oldTab.length > 0) oldTab[0].classList.remove("js-active")

			if (oldTab.length > 0) {
				setTimeout(() => {
					clickedTab.classList.add("js-active")
					clickedTab.nextElementSibling.classList.add("js-in")

					// clear old actives (want this slightly delayed to prevent visibility oddness)
					if (oldTab.length > 0) oldTab[1].classList.remove("js-active")
				}, timing)
			} else {
				clickedTab.classList.add("js-active")
				clickedTab.nextElementSibling.classList.add("js-in")
			}

			setTimeout(() => {
				// clear in and out classes
				if (oldTab.length > 0) oldTab[1].classList.remove("js-out")

				// set new active

				clickedTab.classList.remove("js-in")
				clickedTab.nextElementSibling.classList.remove("js-in")

				// set new active
				clickedTab.nextElementSibling.classList.add("js-active")

				if (window.outerWidth < 991) {
					clickedTab.scrollIntoView({
						behavior: "smooth",
						block: "start",
						inline: "nearest",
					})
				}
			}, timing * 2)
		}
	}

	chonkyTabs.forEach(chonkyTab => {
		const tabBtns = chonkyTab.querySelectorAll(".chonky-tabs__content > button")
		const timing = 300
		chonkyTab.style.setProperty("--timing", `${timing}ms`)

		tabBtns.forEach(tabBtn => {
			tabBtn.addEventListener("click", e => {
				e.preventDefault()

				chonkyTabsTransition(timing, tabBtn, chonkyTab)
			})
		})
	})
}
