//---------------------------------
//===---------------------------===
// Animations - GreenSock
//===---------------------------===

//---------------------------------
document.addEventListener("DOMContentLoaded", event => {
	gsap.registerPlugin(ScrollTrigger, Observer, ScrollToPlugin, MotionPathPlugin)

	if (document.querySelector(".cl-list")) {
		//===-----------------===
		// Compare list animation
		//===-----------------===
		gsap.from(".cl-list--good ul li", {
			scrollTrigger: ".cl-list",
			opacity: 0,
			duration: 0.25,
			y: -100,
			stagger: 0.5,
		})

		gsap.from(".cl-list--bad ul li", {
			scrollTrigger: ".cl-list",
			opacity: 0,
			duration: 0.25,
			y: -100,
			stagger: 0.5,
		})
	}

	//===-----------------===
	// Content fading
	//===-----------------===
	if (document.querySelector(".animate-fade-in")) {
		const fadingSections = document.querySelectorAll(".animate-fade-in")
		fadingSections.forEach(section => {
			const fadeTL = gsap
				.timeline({
					scrollTrigger: {
						trigger: section.firstElementChild,
					},
				})
				.fromTo(
					section,
					{
						autoAlpha: 0,
						marginBottom: "-10rem",
						position: "relative",
						y: 200,
					},
					{ autoAlpha: 1, delay: 0.5, duration: 0.5, marginBottom: 0, y: 0 }
				)
		})
	}

	//===-----------------===
	// Image wheel animate in
	//===-----------------===
	const imageWheel = document.querySelector(".image-wheel")
	if (imageWheel) {
		const imageWheelItems = gsap.utils.toArray(".image-wheel__item")
		const imageWheelContent = document.querySelector(".image-wheel__content")
		const imageWheelTl = gsap
			.timeline({
				scrollTrigger: {
					trigger: imageWheel,
				},
				onComplete: () => {
					const iwD1 = 0.3
					const iwD2 = 0.5
					const iwDelay = 0.7
					const iwTl = gsap.timeline({ repeat: 2 })

					imageWheelItems.forEach(item => {
						const itemSpan = item.querySelector("span")
						iwTl
							.fromTo(
								itemSpan,
								{
									opacity: 0,
								},
								{
									opacity: 1,
									duration: iwD1,
								}
							)
							.to(itemSpan, {
								opacity: 0,
								duration: iwD2,
								delay: iwDelay,
							})

						item.addEventListener("mouseenter", e => {
							iwTl.pause()
						})

						item.addEventListener("mouseleave", e => {
							iwTl.play()
						})
					})
					iwTl.repeat(2)
				},
			})
			.fromTo(
				imageWheel.querySelector(".image-wheel__wrapper"),
				{
					autoAlpha: 0,
					scale: 1.5,
				},
				{ duration: 1, delay: 0.5, autoAlpha: 1, scale: 1 }
			)
		const imageWheelContentTl = gsap
			.timeline({
				scrollTrigger: {
					trigger: imageWheel,
				},
			})
			.fromTo(
				imageWheelContent,
				{
					autoAlpha: 0,
					position: "relative",
					y: 100,
				},
				{ autoAlpha: 1, delay: 0.5, duration: 1, y: 0 }
			)
	}

	// ====---------------====
	// Scroll content - MemeBurrs
	// ====---------------====
	if (document.querySelector(".card-scroll")) {
		let sections = document.querySelector(".cs__content")
		const cardWrapper = document.querySelector(".card-scroll")
		let xScrollCardDesktop = gsap.matchMedia().add("(min-width: 768px)", () => {
			gsap.to(sections, {
				xPercent: -50,
				ease: "none",
				scrollTrigger: {
					trigger: ".card-scroll",
					pin: true,
					scrub: 1,
					start: `-=100`,
					end: `+=1500`,
				},
			})
		})
	}

	// ====---------------====
	// Scroll content - Experience
	// ====---------------====
	if (document.querySelector(".x-scroll-content .x-scroll-content__list")) {
		const xScrollListWrapper = document.querySelector(".x-scroll-content")
		const xScrollList = xScrollListWrapper.querySelector(
			".x-scroll-content__list"
		)
		let xScrollListSections = gsap.utils.toArray(
			".x-scroll-content .x-scroll-content__list > div"
		)
		const listLength = xScrollList.getBoundingClientRect().width

		let xScrollListDesktop = gsap.matchMedia().add("(min-width: 768px)", () => {
			gsap.to(xScrollList, {
				xPercent: -60,
				ease: "none",
				scrollTrigger: {
					trigger: ".x-scroll-content",
					pin: true,
					scrub: 1,
					end: `+=${listLength}`,
				},
			})
		})
	}

	//---------------------------------
	//===---------------------------===
	// Portfolio svg loop
	//===---------------------------===
	//---------------------------------
	if (document.querySelector(".portfolio-circles")) {
		const timeline = gsap.timeline({ repeat: -1 })
		gsap.set(".stage-3", {
			opacity: 0,
		})
		timeline
			.to(".stage-2, .stage-2 path", {
				stroke: "#f05724",
				duration: 0.75,
			})
			.to(".stage-2, .stage-2 path", {
				stroke: "#CDCDCC",
				duration: 0.75,
				delay: 0.5,
			})
			.to(".stage-3", {
				stroke: "#f05724",
				duration: 0.75,
				delay: 0.5,
				opacity: 1,
			})

			.to(".stage-3", {
				duration: 0.75,
				delay: 0.5,
				opacity: 0,
			})
	}
})
